
import {
    defineComponent, ref, Ref,
    PropType
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import setAccess, { FormResult } from '@/components/view/pm/set-access';
import { access, community } from '@/data';
import router, { propertySubRouter, propertyBaseRouter } from '@/router';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        },
        roomId: {
            type: [String, null] as PropType<string | null>,
            default: null
        },
        pid: {
            type: [String, null] as PropType<string | null>,
            default: null
        }
    },
    components: {
        propertyBreadCrumb,
        setAccess
    },
    setup(props) {
        const breadHeaders = [
            {
                label: WordList.RDeviceResidents,
                path: `/${propertyBaseRouter}/${propertySubRouter.resident}`
            },
            {
                label: WordList.ProperAllTextView
            }
        ];
        console.log(1);

        const commit = ref(0);
        const initSelf: Ref<FormResult> = ref({
            Name: '',
            StartDay: '',
            StopDay: '',
            StartTime: '',
            StopTime: '',
            DateFlag: '',
            SchedulerType: '',
            Device: []
        });
        access.getPropertyAllUserAccess(props.id, (data: {
            SelfAccess: {
                BeginTime: string;
                EndTime: string;
                StartTime: string;
                StopTime: string;
                DateFlag: string;
                SchedulerType: string;
                Device: { MAC: string; Relay: string; SecurityRelay?: string }[];
            };
        }) => {
            const ele = {
                Name: '',
                StartDay: data.SelfAccess.BeginTime.split(' ')[0],
                StopDay: data.SelfAccess.EndTime.split(' ')[0],
                StartTime: data.SelfAccess.StartTime,
                StopTime: data.SelfAccess.StopTime,
                DateFlag: data.SelfAccess.DateFlag,
                SchedulerType: data.SelfAccess.SchedulerType,
                Device: data.SelfAccess.Device
            };
            initSelf.value = ele;
        });

        const submit = (param: object) => {
            HttpRequest.post(`v3/web/${community.ProjectType.value}/access/editUserSelfAccessGroup`, { ...param, ID: props.id }, () => {
                router.push(`/${propertyBaseRouter}/${propertySubRouter.residentInfo}?id=${props.id}&type=${community.ProjectType.value}`);
            });
        };

        return {
            breadHeaders,
            initSelf,
            commit,
            submit
        };
    }
});
